import { BlitzPage } from 'blitz'
import { AcademyLaunchingHero } from 'app/core/components/Hero/AcadLaunching/Hero'
import { Layout } from 'app/core/layouts'
// import { GrandLauchingLanding } from 'app/core/components/AcadLaunching/Landing'
// import { AcademyComponent } from 'app/acadcompe/components/Academy'
import { MELanding } from 'app/main-event/components/Landing'

// const HomePage: BlitzPage = () => <AcademyComponent />

// HomePage.getLayout = (page) => <Layout>{page}</Layout>
// const HomePage: BlitzPage = () => <GrandLauchingLanding />
const HomePage: BlitzPage = () => <MELanding />
HomePage.getLayout = (page) => <Layout useFullWidthComponents={true}>{page}</Layout>

// HomePage.getLayout = (page) => (
//   <Layout hero={<AcademyLaunchingHero />} useFullWidthComponents={true}>
//     {page}
//   </Layout>
// )

export default HomePage
